import { cloneElement } from 'react';
import classnames from 'classnames';

import { H3 } from 'components/heading/Heading';
import { Link } from 'components/link/Link';

import s from './ArticleCard.module.scss';

const c = classnames.bind(s);

type Props = {
  image?: React.ReactElement;
  title: string;
  date?: string;
  link: string;
  children?: React.ReactNode;
  image_square?: boolean;
};

export const ArticleCard = ({
  image,
  title,
  date,
  link,
  image_square,
  children,
}: Props) => {
  if (!title || !link) {
    return null;
  }
  return (
    <div className={c(s.articleCard, { [s.square]: image_square })}>
      <div className={s.articleCard__imageWrap}>
        {image &&
          cloneElement(image, {
            className: s.articleCard__image,
          })}
      </div>
      <div className={s.articleCard__content}>
        {date && <time className={s.articleCard__date}>{date}</time>}
        <H3 className={s.articleCard__title} skipGradient>
          <Link className={s.articleCard__link} to={link}>
            {title}
          </Link>
        </H3>
        {children}
      </div>
    </div>
  );
};
